function App() {
  return (
    <div className="App">
      <h1>Hi</h1>
      <h3>Welcome to the corner of my Internet of things...</h3>
    </div>
  );
}

export default App;
